import {render} from 'solid-js/web'
import config from './config'
import {configureStoreFactory} from '@ifs/volt-store'

import {Navigate} from '@solidjs/router'
import {lazy} from 'solid-js'
import icons from 'client-assets'
import voltApi from 'client-api'
import './libs/components'
import './libs/foundation'
import './index.css'
import {AppBootstrap, Spinner} from './libs/foundation'
import {ToastPosition} from './libs/foundation/components/toast/Toast'

const root = document.getElementById('root')

const {UNKNOWN_ERROR, UNKNOWN_API_ERROR, LOGIN_REQUIRED, PLAYER_ENTITLEMENT_REVOKED} = voltApi.VoltError.codes
const ignoredNotification = [
    // Filter out LOGIN_REQUIRED -> shall be handled by to restart starter Kit
    LOGIN_REQUIRED.code,
    // Filter out PLAYER_ENTITLEMENT_REVOKED because this error triggers a purchasing channel flow (channel not entitled)
    PLAYER_ENTITLEMENT_REVOKED.code,
    // Filter out generic errors (not précise enough to display coherent information message)
    config.env !== 'development' && UNKNOWN_ERROR.code,
    config.env !== 'development' && UNKNOWN_API_ERROR.code,
]
render((): any => {
    return (
        <AppBootstrap
            ignoredNotification={ignoredNotification}
            defaultConfig={config}
            storeOptions={{
                voltApi,
                configureStoreFactory,
            }}
            toastPosition={ToastPosition.TOP_CENTER}
            icons={icons}
            getRoutes={indexRoute => {
                return [
                    {
                        path: '/',
                        children: [
                            {
                                path: '/',
                                component: lazy(() => import('./App')),
                                children: [
                                    {
                                        path: ['/'],
                                        component: () => {
                                            return indexRoute && <Navigate href={indexRoute} />
                                        },
                                    },
                                ],
                            },
                            {
                                path: '/oauthredirect',
                                component: lazy(() => import('./libs/foundation/authorization/AuthRedirect')),
                            },
                            {
                                path: '/:parentNode',
                                component: lazy(() => import('./App')),
                                children: [
                                    {
                                        path: ['/', '/:childNode'],
                                        component: lazy(() => import('./libs/components/layout/Layout')),
                                        children: [
                                            {
                                                path: ['/', '/series/:seriesId'],
                                                component: lazy(
                                                    () => import('./libs/components/mainContent/MainContent')
                                                ),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: '/user-settings',
                                component: lazy(() => import('./App')),
                                children: [
                                    {
                                        path: '/',
                                        component: lazy(() => import('./libs/components/layout/Layout')),
                                        children: [
                                            {
                                                path: '/',
                                                component: lazy(() => import('./pages/userSettings/UserSettings')),
                                            },
                                        ],
                                    },
                                ],
                            },
                            // {
                            //     path: '/settings',
                            //     component: lazy(() => import('./pages/settings/Settings')),
                            // },
                            {
                                path: '*',
                                component: lazy(() => import('./pages/notFound/NotFound')),
                            },
                            {
                                path: '/checkout',
                                component: lazy(() => import('./App')),
                                children: [
                                    {
                                        path: '/',
                                        component: lazy(() => import('./libs/components/layout/Layout')),
                                        children: [
                                            {
                                                path: '/',
                                                component: lazy(() => import('./libs/components/checkout/Checkout')),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: '/profile',
                                component: lazy(() => import('./App')),
                                children: [
                                    {
                                        path: '/',
                                        component: lazy(() => import('./libs/components/layout/Layout')),
                                        children: [
                                            {
                                                path: '/',
                                                component: lazy(() => import('./libs/components/profile/Profile')),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: '/profile',
                                component: lazy(() => import('./libs/components/profile/Profile')),
                            },
                            {
                                path: '/transactionActivity',
                                component: lazy(() => import('./App')),
                                children: [
                                    {
                                        path: '/',
                                        component: lazy(() => import('./libs/components/layout/Layout')),
                                        children: [
                                            {
                                                path: '/',
                                                component: lazy(
                                                    () => import('./pages/transactionActivity/TransactionActivity')
                                                ),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: '/faq',
                                component: lazy(() => import('./App')),
                                children: [
                                    {
                                        path: '/',
                                        component: lazy(() => import('./libs/components/layout/Layout')),
                                        children: [
                                            {
                                                path: '/',
                                                component: lazy(() => import('./libs/components/faq/Faq')),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: '/agent',
                                component: lazy(() => import('./careAgent/authorization/CareAgentRedirect')),
                            },
                            {
                                path: '/agent/search',
                                component: lazy(() => import('./App')),
                                children: [
                                    {
                                        path: '/',
                                        component: lazy(() => import('./careAgent/layout/CareAgentLayout')),
                                        children: [
                                            {
                                                path: '/',
                                                component: lazy(
                                                    () => import('./careAgent/landingPage/CareAgentLandingPage')
                                                ),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: '/agent/logout',
                                component: lazy(() => import('./careAgent/logOut/CareAgentLogOutPage')),
                            },
                            {
                                path: '/auth-error',
                                component: lazy(() => import('./pages/authError/AuthError')),
                            },
                        ],
                    },
                ]
            }}
        />
    )
}, root!)
