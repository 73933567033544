export class VindiciaUserSubscription {
    constructor(props) {
        this.id = props.id
        this.vid = props.vid
        this.status = props.status
        this.transactionId = props.transactionId // Used for cancellation
        this.productId = props.productId
        this.starts = props.starts
        this.ends = props.ends
    }

    static STATUS = {
        activating: 'Activating',
        active: 'Active',
        suspended: 'Suspended',
        deactivated: 'Deactivated',
        pending: 'Pending',
        unknown: 'Unknown',
    }

    isEntitled() {
        return (
            this.status === VindiciaUserSubscription.STATUS.activating ||
            this.status === VindiciaUserSubscription.STATUS.active ||
            this.status === VindiciaUserSubscription.STATUS.pending
        )
    }
}
