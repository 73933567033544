export default (props = {}) => <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"{...props}>
<path d="M5 13C3.34315 13 2 14.3431 2 16C2 17 2 18 2 19C2 20.6569 3.34315 22 5 22C6 22 7 22 8 22C9.65685 22 11 20.6569 11 19C11 18 11 17 11 16C11 14.3431 9.65685 13 8 13C7 13 6 13 5 13ZM9 19C9 19.5523 8.55228 20 8 20H5C4.44772 20 4 19.5523 4 19V16C4 15.4477 4.44772 15 5 15H8C8.55228 15 9 15.4477 9 16V19Z" />
<path d="M16 13C14.3431 13 13 14.3431 13 16C13 17 13 18 13 19C13 20.6569 14.3431 22 16 22C17 22 18 22 19 22C20.6569 22 22 20.6569 22 19C22 18 22 17 22 16C22 14.3431 20.6569 13 19 13C18 13 17 13 16 13ZM20 19C20 19.5523 19.5523 20 19 20H16C15.4477 20 15 19.5523 15 19V16C15 15.4477 15.4477 15 16 15H19C19.5523 15 20 15.4477 20 16V19Z" />
<path d="M5 2C3.34315 2 2 3.34315 2 5C2 6 2 7 2 8C2 9.65685 3.34315 11 5 11C6 11 7 11 8 11C9.65685 11 11 9.65685 11 8C11 7 11 6 11 5C11 3.34315 9.65685 2 8 2C7 2 6 2 5 2ZM9 8C9 8.55228 8.55228 9 8 9H5C4.44772 9 4 8.55228 4 8V5C4 4.44772 4.44772 4 5 4H8C8.55228 4 9 4.44772 9 5V8Z" />
<path d="M16 2C14.3431 2 13 3.34315 13 5C13 6 13 7 13 8C13 9.65685 14.3431 11 16 11C17 11 18 11 19 11C20.6569 11 22 9.65685 22 8C22 7 22 6 22 5C22 3.34315 20.6569 2 19 2C18 2 17 2 16 2ZM20 8C20 8.55228 19.5523 9 19 9H16C15.4477 9 15 8.55228 15 8V5C15 4.44772 15.4477 4 16 4H19C19.5523 4 20 4.44772 20 5V8Z" />
<defs>
<linearGradient id="paint0_linear_10797_15209" x1="12" y1="2" x2="12" y2="22" gradientUnits="userSpaceOnUse">
<stop stop-color="#0057B8"/>
<stop offset="1" stop-color="#0057B8"/>
</linearGradient>
<linearGradient id="paint1_linear_10797_15209" x1="12" y1="2" x2="12" y2="22" gradientUnits="userSpaceOnUse">
<stop stop-color="#0057B8"/>
<stop offset="1" stop-color="#0057B8"/>
</linearGradient>
<linearGradient id="paint2_linear_10797_15209" x1="12" y1="2" x2="12" y2="22" gradientUnits="userSpaceOnUse">
<stop stop-color="#0057B8"/>
<stop offset="1" stop-color="#0057B8"/>
</linearGradient>
<linearGradient id="paint3_linear_10797_15209" x1="12" y1="2" x2="12" y2="22" gradientUnits="userSpaceOnUse">
<stop stop-color="#0057B8"/>
<stop offset="1" stop-color="#0057B8"/>
</linearGradient>
</defs>
</svg>
