import { Profile } from 'models'
import { paymentMethodFactory } from './paymentMethod'

const parseProfileStatus = (status) => {
    switch (status) {
        case 'activated':
            return Profile.STATUS.ACTIVATED
        case 'activating':
            return Profile.STATUS.ACTIVATING
        case 'deactivated':
            return Profile.STATUS.DEACTIVATED
        case 'pending':
            return Profile.STATUS.PENDING
        case 'suspended':
            return Profile.STATUS.SUSPENDED
        case 'unknown':
            return Profile.STATUS.UNKNOWN
        default:
            return Profile.STATUS.ACTIVATED
    }
}

/**
 * Create internal account from Backend metadatas
 * @param {Object} userAccounts
 * @param {Object} [userInfo]
 * @returns {Profile}
 */
export const profileFactory = (userAccounts, userInfo = {}, config) => {
    // userId is necessary for using some API
    const { id: userId, attributes: userAttributes = {} } = userInfo
    const { emails = [], mobiles = [] } = userAttributes
    const userEmail = emails.length > 0 ? emails[0].email : undefined
    const userMobile = mobiles.length > 0 ? mobiles[0].number : undefined
    const userLanguage = userAttributes['com.uxpsystems.mint.user.Language']
    if (!userAccounts) {
        // Use Unknown Profile to allow login if not provided by MarketONE
        return new Profile({
            userId,
            firstName: 'unknown',
            username: 'unknown',
            customerId: 'unknown',
            profileId: 'unknown',
            pcLevel: 18,
            dvrEnabled: false,
            dvrQuota: 0,
            dvrQuotaRemaining: 0,
            dvrQuotaUnit: Profile.DVR_QUOTA_UNIT.HOUR,
            email: userEmail,
            address: '',
            mobile: userMobile,
            defaultLanguage: userLanguage,
        })
    }

    const { status, attributes = {} /*id, type, subscriptions = [],*/ } = userAccounts
    const {
        bssName,
        bssAccounts = [],
        paymentProviderId,
        paymentDetails = [],
        paymentProviderAccountId,
        internalProviderAccountId,
    } = attributes

    let displayName = userAccounts.displayName

    if (userAttributes?.['com.uxpsystems.mint.user.GivenName'] || userAttributes?.displayName) {
        displayName =
            userAttributes?.displayName || userAttributes['com.uxpsystems.mint.user.GivenName']
    }

    let profile = {
        userId,
        displayName:
            userInfo?.displayName || userAttributes?.['com.uxpsystems.mint.user.GivenName'],
        username: displayName,
        profileId: userAccounts.id,
        customerId: internalProviderAccountId,
        status: parseProfileStatus(status),
        pcLevel: config.defaultUserPcLevel,
        dvrEnabled: false,
        dvrQuota: 0,
        dvrQuotaRemaining: 0,
        paymentMethods: paymentDetails.map((method) =>
            paymentMethodFactory(method, paymentProviderId, paymentProviderAccountId)
        ),
        // entitlements: Managed through product call flows
        namedProperties: {
            bssName,
        },
        email: userEmail,
        mobile: userMobile,
        defaultLanguage: userLanguage,
        dvrQuotaUnit: Profile.DVR_QUOTA_UNIT.HOUR,
    }
    // This field is not present for all marketone projects (XL Axiata Only)
    if (bssAccounts.length > 0) {
        const mainAccount = bssAccounts[0]
        const { id, accountId, email, language, name, addresses, metadata } = mainAccount
        const address = addresses?.[0]

        profile = {
            ...profile,
            username: name || displayName,
            firstName: '',
            lastName: '',
            defaultLanguage: userLanguage || language,
            profileId: userAccounts.id || id || accountId,
            email: email || userEmail,
            address: address || '',
            metadata: metadata || '',
        }
    }

    return new Profile(profile)
}
