import { of, forkJoin, throwError } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'
import Fetch from '../fetch'
import AuthApi from '../AuthApi/index'
import {
    VindiciaUserSubscriptionFactory,
    updateSubscriptionsEntitlements,
    getSubscribePayload,
    getUnsubscribePayload,
} from '../factories/subscription'
import DataHelper from 'framework/helpers/data'
import VoltError from 'VoltError'

/**
 * Deal with Purchase (data retrieval, purchase and subscription actions)
 * Documentation is available here : https://developer.m1amdocs.com/api/
 */
export default class PurchaseApi extends Fetch {
    static defaultConfig = {
        pageSize: 50,
        retrieveOnlyActiveSubscriptions: true,
    }

    /**
     * Retrieves all Subscription products
     *
     * @param {Number} [pageSize=15] The page size of each api request
     *
     * @returns {Observable<SubscriptionsList>}
     */
    getSubscriptions(pageSize = 15) {
        return forkJoin([
            // GET Package descriptions from the proxy
            this.metaApi.getAllSubscriptions(),
            // THEN GET entitlements statuses for the USER
            this._getUserSubscriptions(),
        ]).pipe(
            mergeMap(([proxySubscriptions, userSubscriptions]) => {
                return of({
                    products: updateSubscriptionsEntitlements(
                        proxySubscriptions,
                        userSubscriptions
                    ),
                    isComplete: true,
                })
            })
        )
    }

    /**
     * From the productId, retrieve subscription data, and pass it to factory
     * to update it with entitlement status
     *
     * @param {Array<String>} productIds An array containing the product identifier
     * as first and unique element
     *
     * @returns {Observable<Array<Subscription>>} A list of {@link Subscription} products
     */
    getSubscriptionsData(productIds) {
        return forkJoin([
            // GET Package descriptions from the proxy
            this.metaApi.getSubscriptionsById({ ids: productIds }),
            // THEN GET entitlements statuses for the USER
            this._getUserSubscriptions(),
        ]).pipe(
            mergeMap(([proxySubscriptions, userSubscriptions]) => {
                return of(updateSubscriptionsEntitlements(proxySubscriptions, userSubscriptions))
            })
        )
    }

    /**
     * Request to purchase a subscription product
     * @param {String} productId
     * @param {String} offerId
     * @param {Object} purchaseData
     * @param {Object} [purchaseData.profile] User Profile (not used)
     * @param {Object} [purchaseData.paymentMethod] Payment method to use for Purchase (e.g Pay on bill, Credit Card, etc..)
     * @param {Object} [purchaseData.billingPlan] Billing Plan to use for Purchase (Buy for 1 month, 6 month, 1 year and pay every Month or for a Year, etc..)
     *
     * @returns {Observable}
     */
    subscribe(productId, offerId, purchaseData) {
        const { profile = {}, paymentMethod } = purchaseData

        let myPaymentMethod = paymentMethod
        if (!paymentMethod) {
            const { paymentMethods = [] } = profile

            myPaymentMethod = paymentMethods.find((elt) => elt.defaultPaymentMethod && elt.active)
        }

        let paymentMethodId =
            (myPaymentMethod && myPaymentMethod.id) ||
            DataHelper.getInstance().getData(DataHelper.STORE_KEY.DEFAULT_PAYMENT_METHOD)
        if (!paymentMethodId) {
            return throwError(
                new VoltError(VoltError.codes.PURCHASE_FAILED_DUE_TO_MISSING_PAYMENT_METHOD, {
                    extraLog:
                        'Neither selected payment method by the user NOR active default payment provided',
                })
            )
        }

        const subscriptionId = productId + paymentMethodId.replace('pay', '') // Ugly demo workaround from smartui I am not sure it is needed
        const body = getSubscribePayload(
            productId,
            DataHelper.getInstance().getData(DataHelper.STORE_KEY.ACCOUNT_ID),
            paymentMethodId,
            subscriptionId,
            new Date().toISOString()
        )

        const url = `${AuthApi.vindiciaApiUrl}/subscriptions`
        return this.fetch({
            url,
            body,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Basic ${AuthApi.vindiciaApiKey}`,
            },
            method: 'POST',
            log: 'PURCHASE SUBSCRIPTION',
        }).pipe(
            mergeMap(({ response }) => {
                this.logger.info('PurchaseApi', `[Transaction succeeded]`)
                return of(null)
            }),
            catchError((error) =>
                throwError(
                    new VoltError(VoltError.codes.PURCHASE_FAILED, {
                        inheritedError: error,
                    })
                )
            )
        )
    }

    /**
     * Request to unsubscribe a subscription product
     * @param {String} productId
     * @param {String} offerId
     * @param {String|Number} transactionId
     *
     * @returns {Observable}
     */
    unsubscribe(productId, offerId, transactionId) {
        if (!transactionId) {
            return throwError(
                new VoltError(VoltError.codes.UNSUBSCRIBE_FAILED, {
                    extraLog: `Cannot proceed to cancellation because transactionId is null`,
                })
            )
        }

        const body = getUnsubscribePayload(
            productId,
            DataHelper.getInstance().getData(DataHelper.STORE_KEY.ACCOUNT_ID),
            DataHelper.getInstance().getData(DataHelper.STORE_KEY.ACCOUNT_VID),
            transactionId,
            transactionId,
            new Date().toISOString()
        )

        const url = `${AuthApi.vindiciaApiUrl}/subscriptions/${transactionId}/actions/cancel`
        return this.fetch({
            url,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Basic ${AuthApi.vindiciaApiKey}`,
            },
            method: 'POST',
            body,
            log: 'CANCEL SUBSCRIPTION',
        }).pipe(
            mergeMap(({ response }) => {
                this.logger.info('PurchaseApi', `[Unsubscribe succeeded]`)
                return of(null)
            }),
            catchError((error) =>
                throwError(
                    new VoltError(
                        VoltError.codes.OPERATION_ALREADY_DONE.code === error.code
                            ? VoltError.codes.UNSUBSCRIBE_ALREADY_DONE
                            : VoltError.codes.UNSUBSCRIBE_FAILED,
                        {
                            inheritedError: error,
                        }
                    )
                )
            )
        )
    }

    /**
     * This method returns the list of entitlements for a USer (list of subscriptions purchased)
     * @returns {Observable<VindiciaUserSubscription>}
     */
    _getUserSubscriptions() {
        const vindiciaAccountId = DataHelper.getInstance().getData(DataHelper.STORE_KEY.ACCOUNT_ID)
        if (!vindiciaAccountId) {
            return throwError(
                new VoltError(VoltError.codes.MISSING_ENTITLEMENT, {
                    extraLog: `Cannot get user subscriptions`,
                })
            )
        }

        // Do not handle recursive fetch for this demo as it seems not working in this instance
        return this.fetch({
            url: `${AuthApi.vindiciaApiUrl}/subscriptions?account=${vindiciaAccountId}`,
            method: 'GET',
            headers: { Authorization: `Basic ${AuthApi.vindiciaApiKey}` },
            log: 'GET USER SUBSCRIPTIONS',
        }).pipe(
            mergeMap(({ response }) => {
                const result =
                    response &&
                    response.data &&
                    response.data.map((x) => VindiciaUserSubscriptionFactory(x))
                return of(result)
            }),
            catchError((error) =>
                throwError(
                    new VoltError(VoltError.codes.MISSING_ENTITLEMENT, {
                        inheritedError: error,
                    })
                )
            )
        )
    }

    // ---[STUBBED FOR Vindicia- CONTENT NOT MANAGED BY THIS BACKEND]---
    /* No VOD provided by Vindicia*/
    getPurchasedTVODProducts({ limit, page }) {
        return of({ isComplete: true, products: [] })
    }

    /* No VOD provided by Vindicia*/
    getAllPurchasedTVODProducts(limit = 15) {
        return of({ isComplete: true, products: [] })
    }

    /* No VOD provided by Vindicia*/
    getTVODProductsData(productIds) {
        return of([])
    }

    /* No VOD provided by Vindicia*/
    purchaseTVOD(productId, offerId) {
        return of({})
    }

    /* No Title (VOD, Channels) defined for package, only 3rd Party Package (Netflix, Catchplay, etc..) */
    getProductsFromTitle({ subscriptionIds, isTvod, id: titleId, streams }) {
        return of([])
    }

    activateSubscription({ productId, activationId }) {
        return of(true)
    }

    /**
     * Redeem a voucher code
     *
     * @param {Object} options
     * @param {String} options.code value of voucherCode
     * @returns {Observable<Boolean>} returns boolean
     */
    redeemVoucherCode = ({ code }) => {
        return of(false)
    }
    // ---------------------------END STUB --------------------------------

    // --------------------- FEATURE NOT SUPPORTED ---------------------
    /**
     * Add payment method for an account
     *
     * @param {Object} options
     * @param {string} options.paymentMethodId the payment method id got from a credit card iframe
     * @returns Observable
     */
    addPaymentMethod = ({ paymentMethodId }) =>
        throwError(new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE))

    /**
     * Delete payment method for an account
     *
     * @param {Object} options
     * @param {string} options.paymentMethodId the payment method id got from a credit card iframe
     * @returns Observable
     */
    deletePaymentMethod = ({ paymentMethodId }) =>
        throwError(new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE))

    sendPurchaseOTP = () => throwError(new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE))
    validatePurchaseOTP = () => throwError(new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE))
    getUserWallet = () => throwError(new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE))
    // ------------------------------------------------------------------
}

/**
 * @typedef {Object} SubscriptionsList
 * @property {Boolean} isComplete Flag indicating if more results are available
 * @property {Array<Subscription>} products A list of products of type {@link Subscription}
 */
