import { of } from 'rxjs'

export default class ConfigurationApi {
    getConfig() {
        return of({})
    }
    shouldUpgrade(appVersion) {
        // simply return of(false) since this service/platform does not offer forceUpgrade capabilities
        return of(false)
    }

    /**
     * Returns custom nodes and requests for the navigation map
     *
     * @returns {Observable<EmiModuleConfiguration>}
     */
    getCustomNavigationMap() {
        return of({
            navMap: {},
            requests: {},
        })
    }

    /**
     * Get Sources of data provided by IFS proxy for this backend
     * @returns {Object}
     */
    getProxyMetadataSourceConfig() {
        return {
            channels: true,
            programs: true,
            subscriptions: false,
            dvr: false,
        }
    }

    isConnectedInOperatorNetwork = () => of(true)
}
