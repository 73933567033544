import VoltError from 'VoltError'
import { ajax } from 'rxjs/ajax'
import { map, catchError, timeoutWith, expand, mergeMap, reduce } from 'rxjs/operators'
import { throwError, EMPTY, of } from 'rxjs'
import MockLogger from 'MockLogger'
import Constants from 'api-constants'

/**
 * Fetching API class.
 */
export default class Fetch {
    constructor(config, { authApi, metaApi }) {
        this.config = config
        this.authApi = authApi
        this.metaApi = metaApi
        this.logger = (config.logger || MockLogger).createChildInstance('vindiciademo')
    }

    /**
     * Default HTTP Settings
     */
    static defaultHttpSettings = {
        METHOD: 'GET',
        HEADERS: {
            'Content-Type': 'application/json',
        },
        pageSize: 50,
    }

    /**
     * Fetching Method
     * @param {String} url
     * @param {Object} body : HTTP URL
     * @param {Object} headers : HTTP Header
     * @param {String} method : GET, PUT, POST, DELETE, HEAD, OPTIONS
     * @param {Number} timeout
     * @param {String} log Extra message for Debug
     * @returns { response, totalCount }
     */
    fetch = ({
        url,
        body,
        headers = Fetch.defaultHttpSettings.HEADERS,
        method = Fetch.defaultHttpSettings.METHOD,
        timeout,
        log = '',
    }) => {
        this.logger.debug(`[${log}][--REQUEST--]: ${url}`, { method, headers, body })

        return ajax({
            url: this._parseUrl(url),
            body,
            headers,
            method,
        }).pipe(
            timeoutWith(
                timeout ||
                    (this.config && this.config.timeout && this.config.timeout.platform) ||
                    Constants.fallbackTimeout.platform,
                throwError(new VoltError(VoltError.codes.REQUEST_TIMEOUT))
            ),
            map((ajaxResponse) => {
                const response = ajaxResponse.response
                const totalCount = this._getTotalCountResponseHeader(ajaxResponse)
                this.logger.trace(`[${log}][--RESPONSE--]`, response)
                return { response, totalCount }
            }),
            catchError((errorObject) => {
                const error = this._parseError(errorObject.response || { error: errorObject.code })
                this.logger.error(`[${log}][--ERROR--]`, {
                    url: this._filterUrlParams(url),
                    method,
                    error,
                })
                return throwError(error)
            })
        )
    }

    /**
     * Recursively executes an api call until all results have been retrieved
     *
     * @param {function} req The function called to fetch elements from the backend
     * @param {Object} opts
     * @param {number} opts.pageSize The number of elements to be retrieved per fetch
     * @param {number} opts.offset The offset of the first element of next backend call
     *
     * @returns {Array<object>} An array of raw elements
     */
    recursiveListFetch(req, opts = {}) {
        const { offset: initialOffset = 0, pageSize = Fetch.defaultHttpSettings.pageSize } = opts

        let offset = initialOffset

        return req(null, pageSize, offset).pipe(
            expand(({ response, totalCount }) => {
                offset += pageSize

                const continueParsing = totalCount && offset < totalCount
                return continueParsing
                    ? req(response, pageSize, offset)
                    : of(response).pipe(mergeMap(() => EMPTY))
            }),
            reduce((acc, { response }) => {
                response && acc.push(...response)
                return acc
            }, [])
        )
    }

    /**
     * Return Total Count of the request
     * @param {*} response including xhr object
     * @returns Total Count or undefined
     */
    _getTotalCountResponseHeader = (response) => {
        const totalCount =
            response && response.xhr && response.xhr.getResponseHeader('X-Total-Count')
        return totalCount && parseInt(totalCount)
    }

    /**
     * Parsing Error
     * @param {Object} response
     * @returns {VoltError}
     */
    _parseError = (response) => {
        const { error, description } = this._extractError(response) || {}

        if (!error) return new VoltError(VoltError.codes.UNKNOWN_API_ERROR)

        let voltError
        switch (error) {
            case 'REQUEST_TIMEOUT':
                voltError = new VoltError(VoltError.codes.REQUEST_TIMEOUT)
                break
            case 'authentication-required':
                voltError = new VoltError(VoltError.codes.INVALID_AUTH_TOKEN, {
                    extraLog: `platform error: ${error}`,
                })
                break
            case 'invalid-request':
            case 'forbidden':
                voltError = new VoltError(VoltError.codes.SERVICE_NOT_ALLOWED, {
                    extraLog: `platform error: ${error} : ${description}`,
                })
                break
            case 'not-found':
                voltError = new VoltError(VoltError.codes.NOT_FOUND, {
                    extraLog: `platform error: ${error} : ${description}`,
                })
                break
            case 'input-conflict':
                voltError = new VoltError(VoltError.codes.OPERATION_ALREADY_DONE, {
                    extraLog: `platform error: ${error} : ${description}`,
                })
                break
            default:
                voltError = new VoltError(VoltError.codes.UNKNOWN_API_ERROR, {
                    extraLog: `platform error: ${error} : ${description}`,
                })
                break
        }

        return voltError
    }

    /**
     * Parses several form of error messaging and return an object which contains the error and th description
     * @param {Object} response
     * @returns {Object} '{ error: 'CODE', description: 'DESCRIPTION' }'
     */
    _extractError(response) {
        if (!response) {
            return { error: 'unknown', description: 'unknown' }
        }
        if (response.object) {
            return {
                error: response.object,
                description: response.message,
            }
        }
        if (response.operationError) {
            return {
                error: response.operationError.code,
                description: response.operationError.message,
            }
        }
        if (response.code) {
            return {
                error: response.code,
                description: response.message,
            }
        }
        if (response.error) {
            return {
                error: response.error,
                description: response.error_description,
            }
        }

        return { error: 'unknown', description: 'unknown' }
    }

    _parseUrl(url) {
        // IMPORTANT : Backend fall in error if we provide a double slash
        return url && url.replace(/([^:]\/)\/+/g, '$1')
    }

    _filterUrlParams(url) {
        // IMPORTANT : To filter for GDPR
        return url && url.split('?')[0]
    }
}
