import {useContext} from 'solid-js'
import {i18nProviderContext} from './i18nProviderContext'
import {defineMessages} from '@formatjs/intl'
import {locales} from 'client-translations'
import {useLocale} from './useLocale'
import {useConfig} from '../components'

export const useI18n = () => {
    const context = useContext(i18nProviderContext)
    const locale = useLocale()
    const options: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZone: 'UTC',
    }

    return {
        defineMessages: (messages: any) => defineMessages(messages),
        formatMessage: (id: any, values?: Record<string, any>) => context?.formatMessage(id, values),
        formatCurrency: (value: number, options?: any) =>
            context?.formatNumber(value || 0, {style: 'currency', ...options}),
        formatNumber: (value: number, options?: any) => context?.formatNumber(value, options),
        formatString: (stringKey: string) => {
            return locales[locale()]?.messages?.[stringKey]
        },
        formatStringWithArgs: (stringKey: string, args: Record<string, any>) => {
            const msg = locales[locale()]?.messages?.[stringKey]
            return msg.replace(/\$\{(.*?)\}/g, (_, key) => args[key] || '')
        },
        formatDate: (dateString: string): string => {
            const date = new Date(dateString)
            return `${date.toLocaleDateString('en-US', options)}(UTC)`
        },
        formatDateLongType: (dateString: string): string => {
            const monthLong = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ]
            const [dateStr, timeStr] = dateString.replace(/\+.*/, '').split('T')
            const [year, month, day] = dateStr.split('-')
            const formattedDate = `${monthLong[parseInt(month) - 1]} ${day}, ${year}`
            const hours = parseInt(timeStr.split(':')[0])
            const hour12 = hours % 12 == 0 ? 12 : hours % 12
            const formattedHr = hour12 < 10 ? `0${hour12}` : hour12
            const minutes = timeStr.split(':')[1]
            const formattedTime = `${formattedHr}:${minutes} ${hours < 12 ? 'AM' : 'PM'}(UTC)`
            return `${formattedDate} ${formattedTime}`
        },
        isPastDate: oldDate => {
            return new Date(oldDate).getTime() < Date.now()
        },
    }
}
