import { VindiciaUserSubscription } from '../models/VindiciaUserSubscription'
import { Offer } from 'models'

/**
 * Parse Market One User Entitlements/Subscription
 * @param {VindiciaUserSubscription} VindiciaUserSubscriptions Market One subscription
 */
export const VindiciaUserSubscriptionFactory = (sub = {}) => {
    const { id, vid, status = 'Active', starts, ends, items: { data = [] } = {} } = sub
    const { product: { id: productId = {} } = {} } = data && data[0]

    return new VindiciaUserSubscription({
        id,
        vid,
        status,
        transactionId: id,
        starts,
        ends,
        productId,
    })
}

/**
 * This method consolidates the list of subscriptions coming from the IFS PROXY by enriching it with
 * Entitlements attributes : transactionId, entitlementStart, entitlementEnd, isEntitled, isAvailable
 * @param {Array<Subscription>} listSubscriptions List of subscriptions coming from the PROXY
 * @param {Array<VindiciaUserSubscription>} VindiciaUserSubscriptions List of Entitlements coming from Vindicia
 * otherwise it would be through email or browser
 */
export const updateSubscriptionsEntitlements = (listSubscriptions, VindiciaUserSubscriptions) => {
    /**
     * Demo add fake offers
     */
    const listSubscriptionsFaked = listSubscriptions.reduce((acc, subscription) => {
        acc.push(
            subscription.update({
                offers: [
                    new Offer({
                        offerId: 'monthly_offer',
                        title: 'Monthly',
                        isActive: true,
                        cycles: 11,
                        period: 1,
                        periodUnit: Offer.UNIT.MONTH,
                        price: 19.99,
                        originalPrice: 19.99,
                        currency: 'USD',
                    }),
                    new Offer({
                        offerId: 'monthly_offer',
                        title: 'Annually',
                        isActive: true,
                        cycles: 0,
                        period: 1,
                        periodUnit: Offer.UNIT.YEAR,
                        price: 39.99,
                        originalPrice: 39.99,
                        currency: 'USD',
                    }),
                ],
            })
        )
        return acc
    }, [])

    /**
     * To reduce computation complexity, create an intermediary object
     * {"productId" : { userSubscription: obj, partnerSubscription: obj }}
     */
    const VindiciaSubs = VindiciaUserSubscriptions.reduce((acc, userSubscription) => {
        const productId = userSubscription.productId
        if (productId) {
            acc[productId] = userSubscription
        }
        return acc
    }, {})
    /**
     * Then enrich entitlements and activation parameters from Subscriptions coming from the proxy
     */
    return listSubscriptionsFaked.reduce((acc, subscription) => {
        const userSubscription = VindiciaSubs && VindiciaSubs[subscription.id]
        if (userSubscription) {
            const isEntitled = userSubscription.status === VindiciaUserSubscription.STATUS.active
            if (isEntitled) {
                acc.push(
                    subscription.update({
                        transactionId: userSubscription.transactionId,
                        entitlementStart:
                            userSubscription.starts && Date.parse(userSubscription.starts),
                        entitlementEnd: userSubscription.ends && Date.parse(userSubscription.ends),
                        isEntitled: isEntitled,
                        isAvailable: true,
                    })
                )
            }
            return acc
        }
        acc.push(
            subscription.update({
                isEntitled: false,
                isAvailable: true,
            })
        )
        return acc
    }, [])
}

export const getSubscribePayload = (productId, accountId, paymentId, subscriptionId, startDate) => {
    const payload = {
        object: 'Subscription',
        id: subscriptionId,
        account: { object: 'Account', id: accountId },
        billing_plan: { object: 'BillingPlan', id: '14 days trial' },
        payment_method: { object: 'PaymentMethod', id: paymentId },
        currency: 'USD',
        description: `Subscription_${subscriptionId}`,
        starts: startDate,
        items: [
            {
                object: 'SubscriptionItem',
                id: subscriptionId,
                product: { object: 'Product', id: productId },
            },
        ],
        minimum_commitment: 0,
        policy: {
            ignore_cvn_policy: 1,
            ignore_avs_policy: 1,
            min_chargeback_probability: 99,
            immediate_auth_failure_policy: 'doNotSaveAutoBill',
            validate_for_future_payment: 0,
        },
    }
    return payload
}

export const getUnsubscribePayload = (
    productId,
    accountId,
    accountVid,
    paymentId,
    subscriptionId,
    startDate
) => {
    const payload = {
        object: 'Subscription',
        id: subscriptionId,
        vid: accountVid,
        account: { object: 'Account', id: accountId },
        billing_plan: { object: 'BillingPlan', id: '14 days trial' },
        payment_method: { object: 'PaymentMethod', id: paymentId },
        currency: 'USD',
        description: `Subscription_${subscriptionId}`,
        status: 'Pending Cancel',
        billing_state: 'Billing Completed',
        starts: startDate,
        entitled_through: startDate,
        items: {
            object: 'List',
            data: [
                {
                    object: 'SubscriptionItem',
                    id: 'sub_1234.1',
                    index: 0,
                    product: { object: 'Product', id: productId },
                    quantity: '1',
                    created: startDate,
                    starts: startDate,
                },
                {
                    object: 'SubscriptionItem',
                    id: subscriptionId,
                    index: 1,
                    product: { object: 'Product', id: 'TEST_PRODUCT' },
                    quantity: 1,
                    created: startDate,
                    starts: startDate,
                },
            ],
            total_count: 2,
        },
        minimum_commitment: 0,
        notify_on_transition: false,
    }
    return payload
}
