import { of, throwError } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'
import Fetch from '../fetch'
import DataHelper from 'framework/helpers/data'
import VoltError from 'VoltError'
import AuthType from 'framework/helpers/auth'
import { Constants } from 'clients/amdocsDemo'

/**
 * Deal with care portal apis
 */
export default class CarePortalApi extends Fetch {
    /**
     * This method searches the user
     * @returns {Observable<Boolean>}
     */
    searchUser(name) {
        const url = `${this.config.carePortal.urls.adminAuthUrl}/platform/rest/v85/admin/search/user/com.uxpsystems.mint.user.RegularUser?keyword=${name}&offset=0&size=20`
        return this.fetch({
            url,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            log: `[SEARCH USER]`,
            body: {},
            redirectOnSessionExpire: false,
        }).pipe(
            mergeMap((response) => {
                return of(response)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.SEARCH_USER_ERROR, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    getAdminUserProfile() {
        const url = `${this.config.carePortal.urls.adminAuthUrl}/platform/rest/v85/user`
        return this.fetch({
            url,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            log: `[GET ADMIN USER PROFILE]`,
            body: {},
            redirectOnSessionExpire: false,
        }).pipe(
            mergeMap((response) => {
                return of(response)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.GET_ADMIN_PROFILE_ERROR, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    adminLogout() {
        const accessToken = DataHelper.getInstance().getPrimaryAccessToken()
        const clientId = this.config.carePortal.agentClientId
        return this.fetch({
            url: `${this.config.carePortal.urls.adminAuthUrl}/oidc/revoke?client_id=${clientId}&token=${accessToken}`,
            method: 'POST',
            headers: {
                'Content-Type': Constants.httpHeader.ContentType.APPLICATION_WWW_FORM_URLENCODED,
            },
            log: 'REVOKE TOKEN ADMIN',
            redirectOnSessionExpire: false,
        }).pipe(
            mergeMap((response) => {
                return of(response)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.ADMIN_LOGOUT_ERROR, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    impersonationUserRequest(userId) {
        return this.fetch({
            url: `${this.config.carePortal.urls.adminAuthUrl}/platform/rest/v85/admin/impersonation/user/${userId}/request`,
            method: 'POST',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            log: 'IMPERSONATE USER REQUEST',
            redirectOnSessionExpire: false,
        }).pipe(
            mergeMap((response) => {
                return of(response)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.IMPERSONATE_USER_REQUEST_ERROR, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    impersonationUserDetails(userId) {
        return this.fetch({
            url: `${this.config.carePortal.urls.adminAuthUrl}/platform/rest/v85/admin/impersonation/user/${userId}/details`,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            log: 'IMPERSONATE USER DETAILS',
            redirectOnSessionExpire: false,
        }).pipe(
            mergeMap((response) => {
                return of(response)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.IMPERSONATE_USER_DETAILS_ERROR, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    getImpersonationToken(userId) {
        return this.fetch({
            url: `${this.config.carePortal.urls.adminAuthUrl}/platform/rest/v85/admin/impersonation/user/${userId}`,
            method: 'POST',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            log: 'GET IMPERSONATE TOKEN',
            redirectOnSessionExpire: false,
        }).pipe(
            mergeMap(({ xhr }) => {
                let token = xhr.getResponseHeader('X-Access-Token')
                return of(token)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.GET_IMPERSONATE_TOKEN_ERROR, {
                        inheritedError: error,
                    })
                )
            })
        )
    }
}
