const AbstractApi = require('../Api').default
const VindiciaApi = require('services/vindicia').default
const ConfigurationApi = require('services/stub/ConfigurationApi').default
const ProxySearchApi = require('services/proxy/SearchApi').default
const Platforms = require('config/platforms').default
const Models = require('models')
const Constants = require('api-constants').default
const VoltError = require('VoltError').default

class VoltApi extends AbstractApi {
    constructor(config) {
        super(config)

        this.searchApi = new ProxySearchApi(this.config, { commonApi: this.commonApi })

        this.platformApi = new VindiciaApi(this.config, {
            metaApi: this.metaApi,
            searchApi: this.searchApi,
            commonApi: this.commonApi,
        })
    }
}

module.exports = {
    PLATFORM_NAME: Platforms.STUB,
    VoltApi,
    ConfigurationApi,
    Models,
    Constants,
    PIN_TYPE: { MASTER: 'MASTER', ADULT: 'ADULT' },
    PIN_SIZE: 4,
    OTP_SIZE: 4,
    VoltError,
}
