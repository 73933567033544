import RecordingsApi from 'services/stub/RecordingsApi'
import AuthApi from './AuthApi'
import ChannelApi from 'services/stub/ChannelApi'
import ProgramApi from 'services/stub/ProgramApi'
import PlayerApi from 'services/stub/PlayerApi'
import SettingsApi from 'services/stub/SettingsApi'
import PurchaseApi from './PurchaseApi'
import FavouritesApi from 'services/stub/FavouritesApi'
import ImageApi from 'services/stub/ImageApi'
import DeviceApi from 'services/stub/DeviceApi'

export default class VindiciaApi {
    constructor(config, otherApis) {
        this.recordingsApi = new RecordingsApi(config, otherApis)
        this.authApi = new AuthApi(config, otherApis)
        this.channelApi = new ChannelApi(config, otherApis)
        this.programApi = new ProgramApi(config, otherApis)
        this.playerApi = new PlayerApi(config, { ...otherApis, programApi: this.programApi })
        this.settingsApi = new SettingsApi(config, otherApis)
        this.purchaseApi = new PurchaseApi(config, { ...otherApis, authApi: this.authApi })
        this.favouritesApi = new FavouritesApi(config, otherApis)
        this.imageApi = new ImageApi(config)
        this.deviceApi = new DeviceApi(config, otherApis)
    }
}
