import {createEffect, createSignal, onMount, Show} from 'solid-js'
import {AuthorizationContext} from './AuthorizationContext'
import {AuthorizationHandler, AuthorizationType, AuthorizerOptions} from './authorizationHandler'
import {LoadingScreen, useConfig} from '../components'
import {profileSelector, useDispatch, useSelector} from '../store'
import {addToCart, fetchProfile, getShoppingCart, purchaseProduct, purchaseUpdateProduct} from 'store-actions'
import {getDeviceInfo, LocalStorageKeys} from '../utils'
import {cloneDeep, isEmpty} from 'lodash'
import useShoppingCart from '../../hooks/useShoppingCart'
import useSoftBundle from '../../hooks/useSoftBundle'

interface AuthorizationProviderProps {
    voltApi: any
    children: any
}
export const AuthorizationProvider = (props: AuthorizationProviderProps) => {
    const [authorizationHandler, setAuthorizationHandler] = createSignal<AuthorizationHandler>()

    const {getConfig} = useConfig()
    const dispatch = useDispatch()
    const profile = useSelector(profileSelector)

    const authConfig: AuthorizerOptions = {
        allowGuest: getConfig().allowGuest,
        clientId: getConfig().clientId,
        clientSecret: getConfig().clientSecret,
        redirectUri: window.location.origin + '/oauthredirect',
        authorizationType: AuthorizationType.REDIRECT,
        responseType: AuthorizationType.TOKEN,
        grant_type: getConfig().grant_type,
        scope: getConfig().OAuthParam.scope,
        authorityConfiguration: {
            authorization_endpoint: getConfig().urls.authUrl + getConfig().OAuthParam.endpoint.auth,
            token_endpoint: getConfig().OAuthParam.endpoint.fullTokenUrl
                ? getConfig().OAuthParam.endpoint.fullTokenUrl
                : getConfig().urls.authUrl + getConfig().OAuthParam.endpoint.token,
            revocation_endpoint: '',
            registration_endpoint: '',
        },
        extras: getConfig().OAuthParam.extras,
    }

    const isAuthPending = useSelector((state: {auth: {isPending: any}}) => state.auth.isPending)
    const [waitForProfile, setWaitForProfile] = createSignal(false)
    const unsupportedDeviceTypes = ['smartphone', 'tablet', 'phablet']
    const isDeviceUnSupported = unsupportedDeviceTypes.includes(getDeviceInfo().device?.type ?? 's')

    onMount(async () => {
        const _authzHandler = new AuthorizationHandler(authConfig)
        setAuthorizationHandler(_authzHandler)
        if (window.location.pathname !== '/oauthredirect' && !isDeviceUnSupported) {
            if (!authConfig.allowGuest && localStorage.getItem(LocalStorageKeys.OIDC_OAUTH_TOKEN) !== null) {
                setWaitForProfile(true)
            }

            if (!profile() && localStorage.getItem(LocalStorageKeys.OIDC_OAUTH_TOKEN) === null) {
                setWaitForProfile(true)
                dispatch(
                    fetchProfile({
                        onSuccess: () => {},
                        onError: error => {
                            console.log(error)
                            setWaitForProfile(false)
                        },
                    })
                )
            }

            if (authConfig.allowGuest) {
                setWaitForProfile(false)
            }
        }
    })

    createEffect(() => {
        if (profile()) {
            dispatch(getShoppingCart())
        }
    })

    return (
        <AuthorizationContext.Provider value={{authorizationHandler: () => authorizationHandler() as any}}>
            <Show when={isAuthPending() || waitForProfile()} fallback={props.children}>
                <LoadingScreen />
            </Show>
        </AuthorizationContext.Provider>
    )
}

export interface AuthorizationProviderBehavior {
    authorizationHandler: () => AuthorizationHandler
}
