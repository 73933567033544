import {createEffect, createSignal, on, onMount, Show} from 'solid-js'
import {authenticate, saveOauthData, pushNotification} from 'store-actions'
import {Spinner, useConfig} from '../components'
import {AuthorizationError} from '@openid/appauth'
import {profileSelector, useDispatch, useSelector} from '../store'
import {AuthorizationHandler, AuthorizationType, AuthorizerOptions} from './authorizationHandler'
import {useNavigate} from '@solidjs/router'
import {NOTIFICATIONS, NOTIFICATION_TYPES} from 'store-constants'

//TODO: TO be injected correctly based on the client
import {getDeviceData} from '../utils'

export const AuthRedirect = () => {
    const {getConfig} = useConfig()
    const profile = useSelector(profileSelector)
    const authenticationStatus = useSelector((s: any) => s.auth.authenticationStatus)

    const navigate = useNavigate()

    const authConfig: AuthorizerOptions = {
        allowGuest: getConfig().allowGuest,
        clientId: getConfig().clientId,
        clientSecret: getConfig().clientSecret,
        redirectUri: window.location.origin + '/oauthredirect',
        authorizationType: AuthorizationType.REDIRECT,
        responseType: AuthorizationType.TOKEN,
        grant_type: getConfig().grant_type,
        scope: '',
        authorityConfiguration: {
            authorization_endpoint: getConfig().urls.authUrl + getConfig().OAuthParam.endpoint.auth,
            token_endpoint: getConfig().OAuthParam.endpoint.fullTokenUrl
                ? getConfig().OAuthParam.endpoint.fullTokenUrl
                : getConfig().urls.authUrl + getConfig().OAuthParam.endpoint.token,
            revocation_endpoint: '',
            registration_endpoint: '',
        },
    }

    const _authzHandler = new AuthorizationHandler(authConfig)

    const dispatch = useDispatch()

    const isAuthPending = useSelector((state: {auth: {isPending: any}}) => state.auth.isPending)

    const [success, setSuccess] = createSignal(false)
    const [isAuthError, setIsAuthError] = createSignal(true)
    const [errorMessage, setErrorMessage] = createSignal('')

    createEffect(
        on(
            () => profile(),
            () => {
                if (profile()) {
                    navigate(_authzHandler?.getCbRedirectUrl?.() || 'root-discovery')
                }
            }
        )
    )

    createEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const errorCode = urlParams.get('error')
        if (errorCode != null && isAuthError()) {
            switch (getConfig().authErrorHandlingType) {
                case 'notification': {
                    navigate(getConfig().authErrorRedirectionUrl || 'root-discovery')
                    dispatch(
                        pushNotification({
                            message: {
                                tag: [NOTIFICATIONS.GENERIC_AUTH_CODE_ERROR],
                            },
                            type: NOTIFICATION_TYPES.FAILURE,
                        })
                    )
                    setIsAuthError(false)
                    break
                }
                case 'redirect': {
                    window.open(getConfig().authErrorRedirectionUrl, '_blank')
                    setIsAuthError(false)
                    break
                }
                case 'error-page': {
                    setIsAuthError(false)
                    console.log('error-page-12312')
                    navigate('/auth-error')
                    break
                }
            }
        }
    })

    onMount(async () => {
        _authzHandler.onSuccessTokenResponse = async tokenResponse => {
            if (tokenResponse) {
                dispatch(
                    saveOauthData({
                        token: tokenResponse.accessToken,
                        refreshToken: tokenResponse.refreshToken ? tokenResponse.refreshToken : undefined,
                        expirationDate: tokenResponse.issuedAt + (tokenResponse?.expiresIn || 0),
                    })
                )

                dispatch(
                    authenticate({
                        oauthToken: tokenResponse.accessToken,
                        oauthTokenExpirationDate: tokenResponse.issuedAt + (tokenResponse?.expiresIn || 0),
                        oauthRefreshToken: tokenResponse.refreshToken ? tokenResponse.refreshToken : undefined,
                        deviceData: getDeviceData(),
                    })
                )
            }
        }
        _authzHandler.onFailureTokenResponse = async (error: AuthorizationError | null) => {}

        _authzHandler.checkForAuthorizationResponse()
    })

    return (
        <div style={{display: 'flex', 'justify-content': 'center', height: '100%', 'align-items': 'center'}}>
            <Show when={isAuthPending()} fallback={!success() && <div>{errorMessage()}</div>}>
                <Spinner />
            </Show>
        </div>
    )
}

export default AuthRedirect
