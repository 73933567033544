import { PaymentMethod } from 'models'

export const paymentMethodFactory = (data, paymentProviderId, paymentProviderAccountId) => {
    const {
        paymentMethodId,
        paymentMethodName,
        active,
        currency,
        primary,
        classType,
        cardNumber,
        expirationMonth,
        expirationYear,
    } = data

    let paymentType
    switch (classType) {
        case 'com.uxpsystems.mint.attributes.CreditCardPayment':
            paymentType = PaymentMethod.PAYMENT_METHOD_TYPE.CREDIT_CARD
            break
        case 'com.uxpsystems.mint.attributes.MerchantAcceptedPayment': // TODO - Map it properly by default assigned to pay on bill
            paymentType = PaymentMethod.PAYMENT_METHOD_TYPE.PAY_ON_BILL
            break

        case 'com.uxpsystems.mint.attributes.ExternalBilling': // TODO - Map it properly by default assigned to pay on bill
            paymentType = PaymentMethod.PAYMENT_METHOD_TYPE.PAY_ON_BILL
            break

        default:
            paymentType = PaymentMethod.PAYMENT_METHOD_TYPE.PAY_ON_BILL
            break
    }

    return new PaymentMethod({
        active,
        id: paymentMethodId,
        externalId: paymentProviderAccountId,
        name: paymentMethodName || cardNumber,
        defaultPaymentMethod: primary,
        paymentPlatform: paymentProviderId,
        currency,
        paymentType,
        cardNumber: cardNumber,
        cardExpirationMonth: expirationMonth,
        cardExpirationYear: expirationYear,
    })
}
