import { Profile, PaymentMethod } from 'models'

/**
 * Create internal account from Backend metadatas
 * @param {Object} data
 * @returns {Profile}
 */
export const profileFactory = (dataProfile, config) => {
    if (!dataProfile) {
        return new Profile({
            userId: 'unknown',
            firstName: 'unknown',
            username: 'unknown',
            customerId: 'unknown',
            profileId: 'unknown',
            pcLevel: 18,
            dvrEnabled: false,
            dvrQuota: 0,
            dvrQuotaRemaining: 0,
            dvrQuotaUnit: Profile.DVR_QUOTA_UNIT.HOUR,
        })
    }

    const { id, vid, email, language, company, name, payment_methods = {} } = dataProfile
    const { data = [] } = payment_methods
    const { id: paymentId, vid: paymentVid } = data && data[0]

    let profile = {
        status: Profile.STATUS.ACTIVATED,
        username: vid,
        firstName: 'John',
        lastName: 'Doe',
        userId: id,
        customerId: vid,
        profileId: email,
        defaultLanguage: language,
        pcLevel: config.defaultUserPcLevel,
        dvrQuota: 0,
        dvrQuotaRemaining: 0,
        dvrQuotaUnit: Profile.DVR_QUOTA_UNIT.HOUR,
        // Fake reusing the same payment method ID (Demo Backend)
        paymentMethods: [
            new PaymentMethod({
                id: paymentId,
                externalId: paymentVid,
                name: 'Pay On Bill',
                defaultPaymentMethod: true,
                paymentPlatform: 'vindicia',
                currency: 'USD',
                paymentType: PaymentMethod.PAYMENT_METHOD_TYPE.PAY_ON_BILL,
            }),
            new PaymentMethod({
                id: paymentId,
                externalId: paymentVid,
                name: 'Credit Card',
                defaultPaymentMethod: false,
                paymentPlatform: 'JP Morgan',
                currency: 'USD',
                paymentType: PaymentMethod.PAYMENT_METHOD_TYPE.CREDIT_CARD,
            }),
            new PaymentMethod({
                id: paymentId,
                externalId: paymentVid,
                name: 'Paypal',
                defaultPaymentMethod: false,
                paymentPlatform: 'Paypal',
                currency: 'EUR',
                paymentType: PaymentMethod.PAYMENT_METHOD_TYPE.PAYPAL,
            }),
        ],
        // entitlements: Managed through product call flows
        namedProperties: {
            company,
            name,
        },
    }

    return new Profile(profile)
}

export const getCreateProfilePayload = (
    accountId,
    email,
    paymentId,
    paymentChannel,
    vindiciaApiUsername,
    vindiciaApiPassword
) => {
    const payload = {
        id: accountId,
        name: 'Amdocs Media',
        company: 'Vindicia',
        email,
        email_type: 'html',
        language: 'en-US',
        default_currency: 'USD',
        shipping_address: {
            name: 'Amdocs Media',
            line1: '3900 W, Almeda Ave',
            city: 'Burbank',
            district: 'CA',
            country: 'USA',
            postal_code: '91505',
        },
        payment_methods: [
            {
                id: paymentId,
                type: 'ExternalBilling',
                active: true,
                external_billing: {
                    bill_receiver: 'CES',
                    payment_channel: paymentChannel,
                    financial_account: '12345678',
                },
                billing_address: {
                    name: 'Amdocs Media',
                    line1: '3900 W, Almeda Ave',
                    city: 'Burbank',
                    district: 'CA',
                    country: 'USA',
                    postal_code: '91505',
                },
            },
        ],
        metadata: {
            username: vindiciaApiUsername,
            password: vindiciaApiPassword,
        },
    }
    return payload
}
