import { of, throwError } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'
import VoltError from 'VoltError'
import * as factories from '../factories'
import Constants from 'api-constants'

import DataHelper from 'framework/helpers/data'
import Fetch from '../fetch'

export default class AuthApi extends Fetch {
    static vindiciaApiUrl = null
    static vindiciaApiKey = null
    static vindiciaApiUsername = null
    static vindiciaApiPassword = null

    constructor(config, otherApis) {
        super(config, otherApis)
        this.config = config
        const {
            apiKey = 'bXdjMjAyMF9zb2FwOk1jOTlQQzFsTTZ4cWVKT1FDUHladExleUxDVDFidkwx',
            apiUrl = 'https://api.staging.us-west.vindicia.com/',
            username = 'mwc2020_soap',
            password = 'Mc99PC1lM6xqeJOQCPyZtLeyLCT1bvL1',
        } = config.vindicia || {}

        // Just a demo project, OSEF about the consistency
        AuthApi.vindiciaApiKey = apiKey
        AuthApi.vindiciaApiUrl = apiUrl
        AuthApi.vindiciaApiUsername = username
        AuthApi.vindiciaApiPassword = password
    }

    /**
     * This function follows the nomenclature from VOLT API.
     * This function does not do the authentication but only check if storage contains
     * Token cached, then refresh the access token if the expirations occurred
     * Throw en error if there is not authentication token
     * @returns {Observable<Boolean>}
     */
    _authenticate = () => {
        return DataHelper.getInstance()
            .readAllData()
            .pipe(
                mergeMap(() => {
                    // If we do not have an access token in storage, redo the authentication process
                    if (!this._hasExistingAccount()) {
                        this.logger.warn(
                            'Vindicia',
                            'No Account Id found. Create a new account at boot'
                        )
                        return this.signUp().pipe(
                            mergeMap(() => {
                                this.logger.info('Vindicia', 'New account created')
                                return of(true)
                            }),
                            catchError(() => {
                                this.logger.error(
                                    'Vindicia',
                                    'Error during signup, authorize authentication (Demo project)'
                                )
                                return of(true)
                            })
                        )
                    }

                    this.logger.info('Vindicia', 'Init : Account still cached')

                    return of(true)
                })
            )
    }

    getProfile() {
        return this._authenticate().pipe(
            mergeMap(() => this.fetchProfile()),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.LOGIN_REQUIRED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    fetchProfile = () => {
        if (!this._hasExistingAccount()) {
            return throwError(
                new VoltError(VoltError.codes.USER_PROFILE_RETRIEVAL_FAILED, {
                    extraLog: 'Missing account cannot retrieve user account',
                })
            )
        }
        return this.fetch({
            url: `${AuthApi.vindiciaApiUrl}/accounts/${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.ACCOUNT_ID
            )}`,
            method: 'GET',
            headers: {
                Authorization: `Basic ${AuthApi.vindiciaApiKey}`,
                'Content-Type': 'application/json',
            },
            log: 'GET USER ACCOUNT',
        }).pipe(
            mergeMap(({ response }) => {
                return of(factories.profileFactory(response, this.config))
            }),
            catchError((err) => {
                this.logger.error('An error has occurred while fetching an account', err)
                return throwError(
                    new VoltError(VoltError.codes.USER_PROFILE_RETRIEVAL_FAILED, {
                        inheritedError: err,
                    })
                )
            })
        )
    }

    /**
     * Sign Up Method
     * @param {Constants.authenticationFactor.DOUBLE_FACTOR|Constants.authenticationFactor.SIMPLE_FACTOR} authFactor
     * Double or Simple Factor Authentication
     * @param {Object} data
     * @param {String} data.username
     * @param {String} data.password
     * @param {String} [data.email]
     * @returns {Observable<String>} Constants.authenticationStatus values
     */
    signUp(authFactor, data) {
        const randomId = Date.now()
        const accountId = `cust_${randomId}`
        const email = `Amdocs.Media_${randomId}@amdocs.com`
        const paymentId = `pay_${randomId}`
        const paymentChannel = `EB_${randomId}`
        const body = factories.getCreateProfilePayload(
            accountId,
            email,
            paymentId,
            paymentChannel,
            AuthApi.vindiciaApiUsername,
            AuthApi.vindiciaApiPassword
        )

        return this.fetch({
            url: `${AuthApi.vindiciaApiUrl}/accounts`,
            method: 'POST',
            headers: {
                Authorization: `Basic ${AuthApi.vindiciaApiKey}`,
                'Content-Type': 'application/json',
            },
            body,
            log: 'CREATE USER ACCOUNT',
        }).pipe(
            mergeMap(({ response }) => {
                const profile = factories.profileFactory(response, this.config)
                return DataHelper.getInstance()
                    .clearAllData()
                    .pipe(
                        mergeMap(() => {
                            DataHelper.getInstance().storeData([
                                [DataHelper.STORE_KEY.ACCOUNT_ID, profile.userId],
                                [DataHelper.STORE_KEY.ACCOUNT_VID, profile.customerId],
                                [
                                    DataHelper.STORE_KEY.DEFAULT_PAYMENT_METHOD,
                                    profile.paymentMethods.find((x) => x.defaultPaymentMethod).id,
                                ],
                            ])
                            return of(Constants.authenticationStatus.SUCCEEDED)
                        })
                    )
            }),
            catchError((err) => {
                this.logger.error('An error has occurred while creating an account', err)
                return throwError(
                    new VoltError(VoltError.codes.SIGNUP_FAILED, {
                        inheritedError: err,
                    })
                )
            })
        )
    }

    /**
     * @returns {Observable}
     */
    login() {
        // Demo Project returns true, Profile methods will create function
        return of(Constants.authenticationStatus.SUCCEEDED)
    }

    logout() {
        return this.clearAllStorageValues()
    }

    getIsOutOfHome() {
        return of(false)
    }

    /**
     * Get the "suspended" state of current user
     *
     * @returns {Observable<boolean>} An observable which emit a boolean (true = is suspended otherwise false)
     */
    getIsSuspended() {
        return of(false)
    }

    _hasExistingAccount() {
        return !!DataHelper.getInstance().getData(DataHelper.STORE_KEY.ACCOUNT_ID)
    }

    initGuestMode() {
        return of(true)
    }

    isBackendAvailable = () => {
        return of(true)
    }
}
